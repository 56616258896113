/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import CheckWebpFeature from "@Components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

const windowGlobal = typeof window !== "undefined" && window
export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/to-rent/)) {
      return false;
    }
  
    if (location.pathname.match(/property-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/commercial-property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/commercial-property\/to-rent/)) {
      return false;
    }
  
    if (location.pathname.match(/commercial-property-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/commercial-property-map\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/new-homes-for-sale/)) {
      return false;
    }
    if (location.pathname.match(/new-homes-map-for-sale/)) {
      return false;
    }

    if (location.pathname.match(/auction-property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/auction-property-map\/for-sale/)) {
      return false;
    }

    if (location.pathname.match(/services\/property-auctions\/past-auctions/)) {
      return false;
    }

    if (location.pathname.match(/services\/property-auctions\/upcoming-auctions/)) {
      return false;
    }
  
    return true;
};